import React from 'react';
import './Header.scss';
import { dosoo_logo } from '../../assets/images';

function Header() {
  return (
    <div className="header-wrapper">
      <a href="https://www.dosoo.net/">
        <img className="header-logo" src={dosoo_logo} alt="도수M로고" />
      </a>
    </div>
  );
}

export default Header;
