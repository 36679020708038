import React, { useState, useRef, useEffect } from 'react';
import '../Footer/Footer.scss';
import { dropdownClosed, dropdownOpened } from '../../assets/images';

function Footer() {
  const [open, setOpen] = useState(false);

  return (
    <div className="footer-wrapper">
      <div>
        <ul className="footer-wrapper-menu">
          <li>
            <a href="https://onemedics.net/"> 회사소개</a>
          </li>
          <li>
            <a href="https://dosoo-law.s3.ap-northeast-2.amazonaws.com/20200804_%EC%9D%B4%EC%9A%A9%EC%95%BD%EA%B4%80_%EB%8F%84%EC%88%98M_ver.1.1.pdf">
              이용약관
            </a>
          </li>
          <li>
            <a href="https://dosoo-law.s3.ap-northeast-2.amazonaws.com/20200804_%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4%EB%B3%B4%ED%98%B8%EB%B0%A9%EC%B9%A8_%EB%8F%84%EC%88%98M_ver.1.3.pdf">
              개인정보보호방침
            </a>
          </li>
          <li>
            <a href="https://dosoo-law.s3.ap-northeast-2.amazonaws.com/202004017_%EC%9C%84%EC%B9%98%EA%B8%B0%EB%B0%98%EC%84%9C%EB%B9%84%EC%8A%A4%EC%9D%B4%EC%9A%A9%EC%95%BD%EA%B4%80_%EB%8F%84%EC%88%98M_ver.1.0.pdf">
              위치기반서비스 이용약관
            </a>
          </li>
        </ul>
        <div className="dropdown">
          <button
            className={`dropdown-btn ${open ? 'opened' : ''}`}
            onClick={() => setOpen(!open)}
          >
            <span>FAMILY SITE</span>
            <img src={open ? dropdownOpened : dropdownClosed} />
          </button>
          {open && (
            <div className="dropdown-content">
              {/* <a href="https://www.daechanhospital.com/">대찬병원</a>
              <a href="#">제대로병원</a> */}
              <a href="https://one-duty.com/landing">원듀티</a>
            </div>
          )}
        </div>
      </div>
      <div className="mark">
        <p>© OneMedicsIndustry All Right Reserved</p>
      </div>
    </div>
  );
}

export default Footer;
