import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Carousel.scss';
import {
  prevBtn,
  prevBtnActive,
  nextBtn,
  nextBtnActive,
} from '../../assets/images';

type Props = {
  type?: string;
  children?: any;
  count?: number;
  setCount?: any;
  webCount?: number;
  setWebCount?: any;
};

function CarouselComponent({
  type,
  children,
  count,
  setCount,
  webCount,
  setWebCount,
}: Props) {
  return (
    <Carousel
      showArrows={false}
      showThumbs={false}
      showStatus={true}
      showIndicators={false}
      // autoPlay
      // infiniteLoop
      renderArrowPrev={(clickHandler) => {
        const minus = () => {
          let initialCount = type === 'web' ? webCount : count;

          if (initialCount) {
            if (initialCount <= 1) {
              return;
            } else {
              if (type === 'web') {
                initialCount--;
                setWebCount(initialCount);
              } else {
                initialCount--;
                setCount(initialCount);
              }
            }
          }

          clickHandler();
        };

        return (
          <button className="prev-btn" onClick={minus}>
            <img
              style={{
                width: '27px',
                height: '50px',
              }}
              src={
                (type !== 'web' && count && count <= 1) ||
                (type === 'web' && webCount && webCount <= 1)
                  ? prevBtn
                  : prevBtnActive
              }
            />
          </button>
        );
      }}
      renderArrowNext={(clickHandler) => {
        const add = () => {
          let initialCount = type === 'web' ? webCount : count;

          if (initialCount) {
            if (type === 'web') {
              if (initialCount >= 6) {
                return;
              } else {
                initialCount++;
                setWebCount(initialCount);
              }
            } else {
              if (initialCount >= 3) {
                return;
              } else {
                initialCount++;
                setCount(initialCount);
              }
            }
          }

          clickHandler();
        };

        return (
          <button className="next-btn" onClick={add}>
            <img
              style={{ height: '50px', width: '27px' }}
              src={
                (count && count >= 3) || (webCount && webCount >= 6)
                  ? nextBtn
                  : nextBtnActive
              }
            />
          </button>
        );
      }}
    >
      {children}
    </Carousel>
  );
}

export default CarouselComponent;
