import React, { useEffect, useRef, useState } from 'react';
import ScrollableAnchor, { goToTop, goToAnchor } from 'react-scrollable-anchor';
import { Carousel } from 'react-responsive-carousel';
import Swiper from 'react-id-swiper';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Navigation from '../Navigation';
import CarouselComponent from '../Carousel';
import './Main.scss';
import {
  ipad,
  hospitalMonitor,
  googlePlayBtn,
  appStoreBtn,
  scrollIcon,
  scrollUpIcon,
  scrollDown,
  mobileSectionBg,
  webSectionBg,
  firstImg,
  secondImg,
  thirdImg,
  secondSlide_firstImg,
  secondSlide_secondImg,
  thirdSlide_firstImg,
  thirdSlide_secondImg,
  webFirstImg,
  webSecondImg,
  webThridImg,
  webFourthImg,
  webFifthImg,
  webSixthImg,
  mobileScreen,
} from '../../assets/images';

function Main() {
  const [count, setCount] = useState(1);
  const [webCount, setWebCount] = useState(1);
  /**
   * TODO
   * scroll top 기능
   */
  // const ref = useRef(null);
  // const [inactive, setInactive] = useState(false);

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll, true);
  // }, []);

  // const handleScroll = () => {
  //   if (window.scrollY >= document.body.scrollHeight - 1200) {
  //     setInactive(true);
  //   } else {
  //     setInactive(false);
  //   }
  // };

  return (
    <ScrollableAnchor id={'/'}>
      <div className="main-wrapper">
        <ScrollableAnchor id={'intro'}>
          <section className="main-intro">
            <div className="main-intro-background">
              <div className="main-intro-main-wrapper">
                <div className="main-intro-content-main-wrapper">
                  <div className="main-intro-content-wrapper">
                    <p className="main-intro-content-wrapper-main-text">
                      일상의 여유는 <br />
                      <span>작은 편리함</span>에서 시작됩니다.
                    </p>
                    <p className="main-intro-content-wrapper-sub-text">
                      도수치료 예약 / 관리 서비스
                      <span className="main-intro-content-wrapper-sub-text-highlight1">
                        &nbsp;도수
                      </span>
                      <span className="main-intro-content-wrapper-sub-text-highlight2">
                        &nbsp;M
                      </span>
                    </p>
                  </div>

                  <div className="main-intro-btn-wrapper">
                    <div className="main-intro-btn-wrapper-img-wrapper">
                      <a href="https://play.google.com/store/apps/details?id=com.onemedicsindustry.dosoo">
                        <img
                          className="main-intro-btn-google"
                          src={googlePlayBtn}
                          alt="구글 플레이 버튼"
                        />
                      </a>
                    </div>
                    <div className="main-intro-btn-wrapper-img-wrapper">
                      <a href="https://apps.apple.com/kr/app/%EB%8F%84%EC%88%98m/id1525812818?l=en">
                        <img
                          className="main-intro-btn-app"
                          src={appStoreBtn}
                          alt="앱스토어 버튼"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="main-intro-empty"></div>
              </div>
              <div className="main-intro-scroll-icon-wrapper">
                <img
                  className="main-intro-scroll-icon"
                  src={scrollIcon}
                  alt="스크롤 표시 아이콘"
                />
              </div>
            </div>
            <div className="main-intro-description">
              <div className="main-intro-description-content-wrapper">
                <div>
                  <img
                    className="main-intro-description-ipad"
                    src={ipad}
                    alt="ipad 아이콘"
                  />
                </div>
                <span className="main-intro-description-content-wrapper-text">
                  <span className="main-intro-description-content-wrapper-text-highlight">
                    도수M
                  </span>
                  은, 사용자에게는 예약의 편리함을 <br />
                  병원에는 체계적인 예약관리의 효율성을 제공해드립니다.
                </span>
                <div>
                  <img
                    className="main-intro-description-monitor"
                    src={hospitalMonitor}
                    alt="병원 모니터 아이콘"
                  />
                </div>
              </div>
            </div>
          </section>
        </ScrollableAnchor>

        <ScrollableAnchor id={'about'}>
          <section className="main-about">
            <div className="main-about-background">
              <div className="main-about-wrapper">
                <div className="main-about-wrapper-text">
                  <p className="main-about-wrapper-text-title">
                    <span>도수치료 예약,</span> 어떻게 하시나요.
                  </p>
                  <p className="main-about-wrapper-text-content">
                    예약을 위해 여기저기 전화를 하고, <br />
                    담당자를 찾아 한참을 기다리던 경험은 이제 그만 ~ <br />
                    내가 원하는 날짜와 시간에 맞춰
                    <br />
                    스마트폰 터치 몇 번으로 간단히 예약할 수 있는
                    <br />
                    <span>도수M</span>을 경험해보세요.
                  </p>
                  <p className="main-about-wrapper-text-bottom">
                    스마트한 모바일 시대, <br />
                    도수치료 예약도 똑똑해졌습니다.
                  </p>
                </div>
                <div className="main-about-img-container">
                  <div className="main-about-img-container-wrapper">
                    <img src={mobileScreen} alt="모바일 대표 이미지" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </ScrollableAnchor>

        <ScrollableAnchor id={'mobile'}>
          <section className="main-mobile">
            <div className="main-mobile-background">
              <div className="main-mobile-main-carousel-wrapper">
                {/* 전체 슬라이드 */}
                <CarouselComponent count={count} setCount={setCount}>
                  {/* 첫 번째 슬라이드 */}
                  <div>
                    <div className="main-mobile-sub-carousel">
                      <Carousel
                        showArrows={false}
                        showThumbs={false}
                        infiniteLoop
                        // autoPlay
                      >
                        <div>
                          <img src={firstImg} />
                        </div>
                        <div>
                          <img src={secondImg} />
                        </div>
                        <div>
                          <img src={thirdImg} />
                        </div>
                      </Carousel>
                      <div className="main-mobile-main-carousel-content">
                        <p className="main-mobile-main-carousel-content-title">
                          모바일 예약 서비스
                          <span>{`${count} of 3`}</span>
                        </p>
                        <p className="main-mobile-main-carousel-content-content">
                          Mobile로 간편하게 <br />
                          <span>치료사, 치료 날짜, 시간, 예약 완료</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* 두 번째 슬라이드 */}
                  <div>
                    <div className="main-mobile-sub-carousel">
                      <Carousel
                        showArrows={false}
                        showThumbs={false}
                        infiniteLoop
                        // autoPlay
                      >
                        <div>
                          <img src={secondSlide_firstImg} />
                        </div>
                        <div>
                          <img src={secondSlide_secondImg} />
                        </div>
                      </Carousel>
                      <div className="main-mobile-main-carousel-content">
                        <p className="main-mobile-main-carousel-content-title">
                          모바일 예약 서비스
                          <span>{`${count} of 3`}</span>
                        </p>
                        <p className="main-mobile-main-carousel-content-content">
                          가족 누구라도 등록만 해두면 <br />
                          내가 대신 <span>예약, 결제 가능</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* 세 번째 슬라이드 */}
                  <div>
                    <div className="main-mobile-sub-carousel">
                      <Carousel
                        showArrows={false}
                        showThumbs={false}
                        infiniteLoop
                        // autoPlay
                      >
                        <div>
                          <img src={thirdSlide_firstImg} />
                        </div>
                        <div>
                          <img src={thirdSlide_secondImg} />
                        </div>
                      </Carousel>
                      <div className="main-mobile-main-carousel-content">
                        <p className="main-mobile-main-carousel-content-title">
                          모바일 예약 서비스
                          <span>{`${count} of 3`}</span>
                        </p>
                        <p className="main-mobile-main-carousel-content-content">
                          치료 후 원무과에 가는 수고는 그만! <br />
                          편리한 <span> Mobile 결제 기능</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </CarouselComponent>
              </div>
            </div>
          </section>
        </ScrollableAnchor>

        <ScrollableAnchor id={'web'}>
          <section className="main-web-intro">
            <div className="main-web-intro-background">
              <div className="main-web-intro-main-wrapper">
                <div className="main-web-intro-wrapper">
                  <p className="main-web-intro-title">
                    <span>예약관리</span>에도 품격이 있습니다.
                  </p>
                  <p className="main-web-intro-content">
                    복잡하고 까다로운 환자 관리, 정신없는 예약과 취소 관리{' '}
                    <br />
                    여전히 엑셀에만 의존하고 계시다면...
                    <br />
                    환자별 예약과 취소 스케줄이 자동으로 등록되고
                    <br />
                    예약률과 취소율, 결제금액 통계까지 한 번에 볼 수 있는
                    <br />
                    예약관리 플랫폼 도수M 도입이 시급합니다.
                  </p>
                  <p className="main-web-intro-bottom">
                    치료사분들이 더 만족할 서비스,{' '}
                    <span className="highlight1">도수</span>
                    <span className="highlight2">M</span>
                  </p>
                </div>
                <div className="main-web-intro-empty"></div>
              </div>
            </div>
          </section>
        </ScrollableAnchor>

        <section className="main-web">
          <div className="main-web-background">
            <div className="main-web-main-carousel-wrapper">
              {/* 전체 슬라이드 */}
              <CarouselComponent
                type="web"
                webCount={webCount}
                setWebCount={setWebCount}
              >
                {/* 첫 번째 슬라이드 */}
                <div>
                  <div className="main-web-sub-carousel">
                    <div className="main-web-main-carousel-content">
                      <p className="main-web-main-carousel-content-title">
                        예약 관리 서비스
                        <span>{`${webCount} of 6`}</span>
                      </p>
                      <p className="main-web-main-carousel-content-content">
                        <span>PC, Mobile</span> 두 개의 Platform으로 <br />
                        근무 효율 및 명확한 관리체계 제공
                        <br />
                      </p>
                    </div>
                    <div className="main-web-sub-carousel-image">
                      <img src={webFirstImg} />
                    </div>
                  </div>
                </div>

                {/* 두 번째 슬라이드 */}
                <div>
                  <div className="main-web-sub-carousel">
                    <div className="main-web-main-carousel-content">
                      <p className="main-web-main-carousel-content-title">
                        예약 관리 서비스
                        <span>{`${webCount} of 6`}</span>
                      </p>
                      <p className="main-web-main-carousel-content-content">
                        <span>Click, Drag & Drop 기능</span>을 이용한 <br />
                        편리한 근무 설정 및 관리
                        <br />
                      </p>
                    </div>
                    <div className="main-web-sub-carousel-image">
                      <img src={webSecondImg} />
                    </div>
                  </div>
                </div>

                {/* 세 번째 슬라이드 */}
                <div>
                  <div className="main-web-sub-carousel">
                    <div className="main-web-main-carousel-content">
                      <p className="main-web-main-carousel-content-title">
                        예약 관리 서비스
                        <span>{`${webCount} of 6`}</span>
                      </p>
                      <p className="main-web-main-carousel-content-content">
                        <span>고객 관리기능</span>을 통해
                        <br />
                        치료 환자에 대한 지속적인 책임관리
                        <br />
                      </p>
                    </div>
                    <div className="main-web-sub-carousel-image">
                      <img src={webThridImg} />
                    </div>
                  </div>
                </div>

                {/* 네 번째 슬라이드 */}
                <div>
                  <div className="main-web-sub-carousel">
                    <div className="main-web-main-carousel-content">
                      <p className="main-web-main-carousel-content-title">
                        예약 관리 서비스
                        <span>{`${webCount} of 6`}</span>
                      </p>
                      <p className="main-web-main-carousel-content-content">
                        <span>실시간 Data 수집</span>을 통한 항목별
                        <br />
                        통계자료로 효율적인 운영 관리
                        <br />
                      </p>
                    </div>
                    <div className="main-web-sub-carousel-image">
                      <img src={webFourthImg} />
                    </div>
                  </div>
                </div>

                {/* 다섯번 째 슬라이드 */}
                <div>
                  <div className="main-web-sub-carousel">
                    <div className="main-web-main-carousel-content">
                      <p className="main-web-main-carousel-content-title">
                        예약 관리 서비스
                        <span>{`${webCount} of 6`}</span>
                      </p>
                      <p className="main-web-main-carousel-content-content">
                        <span>예약, 취소 처리기능</span>으로 App 예약이
                        <br />
                        익숙지 않은 환자도 수월하게 관리
                        <br />
                      </p>
                    </div>
                    <div className="main-web-sub-carousel-image">
                      <img src={webFifthImg} />
                    </div>
                  </div>
                </div>

                {/* 여섯 번째 슬라이드 */}
                <div>
                  <div className="main-web-sub-carousel">
                    <div className="main-web-main-carousel-content">
                      <p className="main-web-main-carousel-content-title">
                        예약 관리 서비스
                        <span>{`${webCount} of 6`}</span>
                      </p>
                      <p className="main-web-main-carousel-content-content">
                        <span>마스터 계정</span>만이 접근 가능한
                        <br />
                        치료사 Profile 관리
                        <br />
                      </p>
                    </div>
                    <div className="main-web-sub-carousel-image">
                      <img src={webSixthImg} />
                    </div>
                  </div>
                  <img className="web-section-bg" />
                </div>
              </CarouselComponent>
            </div>
          </div>
          <Navigation status="web" />
          <img
            className="scroll-up-btn"
            src={scrollUpIcon}
            onClick={() => goToTop()}
          />
        </section>
      </div>
    </ScrollableAnchor>
  );
}

export default Main;
