import React, { useState, useEffect } from 'react';
import './Navigation.scss';

type Props = {
  status: string;
};
function Navigation({ status }: Props) {
  return (
    <div className="navigation">
      <div className={status === 'intro' ? 'selected' : ''}>
        <span></span>
        <a href="#intro">INTRO</a>
      </div>
      <div className={status === 'about' ? 'selected' : ''}>
        <span></span>
        <a href="#about">ABOUT</a>
      </div>
      <div className={status === 'mobile' ? 'selected' : ''}>
        <span></span>
        <a href="#mobile">MOBILE</a>
      </div>
      <div className={status === 'web' ? 'selected' : ''}>
        <span></span>
        <a href="#web">WEB</a>
      </div>
    </div>
  );
}
export default Navigation;
